import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiAuthorizationModule } from './api-authorization/api-authorization.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { AgGridModule } from 'ag-grid-angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AgHttpInterceptor } from './lib/interceptors/agHttpInterceptor';
import { AppInitializer } from './app-initializer';
import { ToastrModule } from 'ngx-toastr';
import { SharedModule } from './shared/shared.module';
import { WindowModule } from "@progress/kendo-angular-dialog";
import { DialogsModule } from "@progress/kendo-angular-dialog";
import { NotificationModule } from "@progress/kendo-angular-notification";
import { DocumentUploadModule } from './modules/document-upload/document-upload.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ApiAuthorizationModule,
    ToastrModule.forRoot({ positionClass: 'toast-top-center', toastClass: "ngx-toastr toast-top-offset" }),
    AgGridModule,
    SharedModule.forRoot(),
    AppRoutingModule,
    WindowModule,
    DialogsModule,
    NotificationModule,
    NgSelectModule,
    DocumentUploadModule,
    FontAwesomeModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AgHttpInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: (appInitializer: AppInitializer) => appInitializer.init(),
      deps: [AppInitializer],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
