import { Component, Input } from '@angular/core';
import { ContactInfo } from './models/contact';

@Component({
  selector: 'app-key-contacts-card',
  templateUrl: './key-contacts-card.component.html',
  styleUrls: ['./key-contacts-card.component.scss']
})
export class KeyContactsCardComponent {

  @Input() title: string = "Key Contacts";
  @Input() contacts: ContactInfo[] = [];

  @Input() darkBackground: boolean = false;

}
