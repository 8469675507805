import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { AgGridModule } from 'ag-grid-angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { IntlModule } from '@progress/kendo-angular-intl';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { GridsterModule } from 'angular-gridster2';
import { UIModule } from "../lib/ui/ui.module";
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { PhoneNumberPipe } from "./pipes/phone-number.pipe";
import { PadLeftPipe } from './pipes/pad-left.pipe';
import { NoDataPlaceholderComponent } from './components/no-data-placeholder/no-data-placeholder.component';
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { PageTitleComponent } from './components/page-title/page-title.component';
import { UserAvatarComponent } from './components/user-avatar/user-avatar.component';
import { KeyContactsCardComponent } from './components/key-contacts-card/key-contacts-card.component';
import { ALStatusDisplayNamePipe } from "./pipes/al-status-display-name.pipe";
import { ThumbnailImageNamePipe } from "./pipes/thumbnail-image-name.pipe";

@NgModule({
  declarations: [
    LoadingSpinnerComponent,
    PhoneNumberPipe,
    PhoneNumberPipe,
    PadLeftPipe,
    NoDataPlaceholderComponent,
    PageTitleComponent,
    UserAvatarComponent,
    KeyContactsCardComponent,
    ALStatusDisplayNamePipe,
    ThumbnailImageNamePipe
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    AgGridModule,
    NgbModule,
    UIModule,
    NgSelectModule,
    IntlModule,
    DateInputsModule,
    GridsterModule,
    FontAwesomeModule,
    ReactiveFormsModule
  ],
  exports: [
    CommonModule,
    RouterModule,
    FormsModule,
    AgGridModule,
    NgbModule,
    UIModule,
    NgSelectModule,
    GridsterModule,
    LoadingSpinnerComponent,
    PhoneNumberPipe,
    PhoneNumberPipe,
    PadLeftPipe,
    NoDataPlaceholderComponent,
    FontAwesomeModule, 
    ReactiveFormsModule,
    PageTitleComponent,
    UserAvatarComponent,
    KeyContactsCardComponent,
    ALStatusDisplayNamePipe,
    ThumbnailImageNamePipe
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: []
    }
  }
}
