import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgbAccordionModule, NgbActiveModal, NgbModalModule } from "@ng-bootstrap/ng-bootstrap";
import { AgModal, AgModalContent, AgModalFooter, AgModalHeader, AgModalTitle } from "./modal/ag-modal.component";
import { AgGridModule } from 'ag-grid-angular';
import { NgSelectModule } from "@ng-select/ng-select";

@NgModule({
  declarations: [
    AgModalHeader,
    AgModalTitle,
    AgModalContent,
    AgModalFooter,
    AgModal
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    NgbAccordionModule,
    NgbModalModule,
    NgbModalModule,
    AgGridModule,
    NgSelectModule
  ],
  exports: [
    AgModalHeader,
    AgModalTitle,
    AgModalContent,
    AgModalFooter,
    AgModal
  ],
  providers: [
    NgbActiveModal
  ]
})
export class UIModule { }
