import { AfterViewInit, Component, OnInit } from '@angular/core';

@Component({
  selector: 'body[root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {

  constructor( ) { }

  ngOnInit() { }

  ngAfterViewInit(): void { }
}
