import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IconDefinition, faArrowUpFromBracket, faCheck, faDownLeftAndUpRightToCenter, faUpRightAndDownLeftFromCenter, faXmark, } from '@fortawesome/free-solid-svg-icons';
import { UploadWindowSize } from '@enums/document.enum';
import { ActionCompleteEventArgs, RemovingEventArgs, SelectedEventArgs, UploaderComponent, UploadingEventArgs, FileInfo } from '@syncfusion/ej2-angular-inputs';
import { environment } from 'src/environments/environment';
import { AuthorizeService, IUser } from 'src/app/api-authorization/authorize.service';
import { Subscription } from 'rxjs';
import { NotifyService } from 'src/app/lib/services/notify/notify.service';
import { DocumentUiService } from '@services/document/document-ui.service';
import { TriggerUploadActionParams, UploadWindowState } from '@interfaces/document.interface';

enum WindowTitlebarState { Default, Uploading, Success, Error }

interface ExtendedFileInfo {
  uploadId: string;
  baseFilePath: string;
  relativeFilePath: string;
  fileName: string;
  totalFileSize: number;
  documentRequestItemId: number;
}

@Component({
  selector: 'document-upload-window',
  templateUrl: './document-upload-window.component.html',
  styleUrls: ['./document-upload-window.component.scss']
})
export class DocumentUploadWindowComponent implements OnInit, OnDestroy {

  readonly WindowTitlebarState: typeof WindowTitlebarState = WindowTitlebarState;
  readonly UploadWindowSize: typeof UploadWindowSize = UploadWindowSize;

  user: IUser | null;
  clientId: number;

  //Window Component
  windowVisible: boolean = true;
  windowState: UploadWindowState;
  windowWidth: number = 600;
  windowHeight: number = 350;
  windowTop: number = 15;
  windowLeft: number = 15;

  readonly windowPos: any = {
    minimized: {
      width: 600, height: 350, leftOffset: 15, topOffset: 15
    },
    maximized: {
      width: 800, height: 800, leftOffset: 15, topOffset: 15
    }
  };

  windowTitleText: string;
  windowTitlebarState: WindowTitlebarState;

  windowTitlebarClass: string;
  windowTitleIcon: IconDefinition;

  successfulUploadCount: number = 0;
  activeUploadCount: number = 0;

  uploadWindowStateSubscription: Subscription;
  hideWindowTimeout: any;

  //Icons
  iconXmark: IconDefinition = faXmark;
  iconMinimize: IconDefinition = faDownLeftAndUpRightToCenter;
  iconMaximize: IconDefinition = faUpRightAndDownLeftFromCenter;

  //Uploader Component
  @ViewChild("uploader") uploader: UploaderComponent;

  uploaderAsyncSettings: { saveUrl: string, chunkSize: number };

  dropZoneElement?: HTMLElement;
  uploaderIsDirectoryUpload: boolean;
  selectedDocumentRequestItemId: number;
  currentFilePath: string = "";

  subscriptions$: Subscription = new Subscription();

  uploaderDropZoneElementSubscription: Subscription;
  uploaderDirectoryUploadSubscription: Subscription;
  selectedDocumentRequestItemIdSubscription: Subscription;
  uploadActionTriggerSubscription: Subscription;
  selectedFolderNodeSubscription: Subscription;

  extendedFileInfo: { [id: string]: ExtendedFileInfo } = {};
  blockedFileTypes: string[] = ["exe", "bat", "dll", "com", "cmd", "inf", "ipa", "osx", "pif", "run", "wsh", "xlsm", "docm", "xlsb", "xlam", "pptm"];

  constructor(
    private documentUiService: DocumentUiService,
    private authorizeService: AuthorizeService,
    private notifyService: NotifyService
  ){ }

  ngOnInit(): void {
    this.setWindowTitlebarState(WindowTitlebarState.Default);
    
    this.subscriptions$.add(this.documentUiService.getUploadWindowState().subscribe(state => this.updateWindowState(state)));
    
    this.subscriptions$.add(this.documentUiService.getUploaderDropZoneElement().subscribe(element => this.dropZoneElement = element));
    
    this.subscriptions$.add(this.documentUiService.getUploaderDirectoryUpload().subscribe(isDirectoryUpload => this.uploaderIsDirectoryUpload = isDirectoryUpload));
    
    this.subscriptions$.add(this.documentUiService.getSelectedDocumentRequestId().subscribe(documentRequestItemId => this.selectedDocumentRequestItemId = documentRequestItemId));
    
    this.subscriptions$.add(this.documentUiService.onFolderNodeSelected().subscribe(node => this.currentFilePath = node ? node.fullFilePath : ""));
    
    this.subscriptions$.add(this.documentUiService.onUploadActionTrigger().subscribe(params => this.triggerUploadAction(params)));

    this.subscriptions$.add(
      this.authorizeService.getUser().subscribe(user => {
        //Get the current client id and set the uploader save url
        this.user = user;
        this.clientId = parseInt(user?.ClientId || "");
  
        this.uploaderAsyncSettings = { saveUrl: `${environment.ApiSettings.BlobMicroServiceApp.BaseUrl}/clients/${this.clientId}/blobs/upload`, chunkSize: 20971520 };
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions$.unsubscribe();
  }

  //Upload Window Methods
  setWindowTitlebarState(state: WindowTitlebarState): void {
    
    this.windowTitlebarState = state;

    switch(state){
      case WindowTitlebarState.Default:
        this.windowTitleIcon = faArrowUpFromBracket;
        this.windowTitleText = "Document Upload";
        this.windowTitlebarClass = "";
        break;
      
      case WindowTitlebarState.Uploading:
        this.windowTitleIcon = faArrowUpFromBracket;
        this.windowTitleText = "Upload In Progress";
        this.windowTitlebarClass = "titlebar-uploading";
        break;

      case WindowTitlebarState.Success:
        this.windowTitleIcon = faCheck;
        this.windowTitleText = "Upload Complete";
        this.windowTitlebarClass = "titlebar-success";
        break;

      case WindowTitlebarState.Error:
        this.windowTitleIcon = faXmark
        this.windowTitleText = "Upload Complete with Error(s)";
        this.windowTitlebarClass = "titlebar-error";
        break;
    }
    
  }

  setUploadWindowSize(size: UploadWindowSize): void {
    this.documentUiService.setUploadWindowSize(size);
  }

  setUploadWindowVisible(isVisible: boolean): void {
    this.documentUiService.setUploadWindowVisible(isVisible);
  }

  updateWindowState(state: UploadWindowState): void {
    this.windowState = state;
    this.updateWindowLayout();
  }

  updateWindowLayout(): void{
    switch(this.windowState.size){
      
      case UploadWindowSize.Minimized:
        this.windowWidth = Math.min((window.innerWidth - 100), this.windowPos.minimized.width);
        this.windowHeight = Math.min((window.innerHeight - 200), this.windowPos.minimized.height);
        this.windowLeft = -this.windowWidth - this.windowPos.minimized.leftOffset;
        this.windowTop = -this.windowHeight - this.windowPos.minimized.topOffset;
        break;

      case UploadWindowSize.Maximized:
        this.windowWidth = Math.min((window.innerWidth - 100), this.windowPos.maximized.width);
        this.windowHeight = Math.min((window.innerHeight - 200), this.windowPos.maximized.height);
        this.windowLeft = -this.windowWidth - this.windowPos.maximized.leftOffset;
        this.windowTop = -this.windowHeight - this.windowPos.maximized.topOffset;
        break;
    }
  }

  //Uploader Events
  triggerUploadAction(params: TriggerUploadActionParams): void {
    this.selectedDocumentRequestItemId = params.documentRequestItemId;
    this.uploaderIsDirectoryUpload = params.isDirectoryUpload;
    (document.getElementsByClassName('e-file-select')[0].querySelector('input') as HTMLInputElement).webkitdirectory = params.isDirectoryUpload;
    (document.getElementsByClassName('e-file-select-wrap')[0].querySelector('button') as HTMLButtonElement).click();
  }

  onSelected(args: SelectedEventArgs): void {

    clearTimeout(this.hideWindowTimeout);
    this.setUploadWindowVisible(true);

    for(let file of args.filesData){
      let validFileType: boolean = this.validateFileType(file);

      if(!validFileType) {
        args.isModified = true; 
        continue; 
      }

      this.extendedFileInfo[file.id!] = this.createExtendedFileInfo(file);
    }
  }

  validateFileType(fileInfo: FileInfo): boolean {
    if(this.blockedFileTypes.includes(fileInfo.type)) {
      fileInfo.status = "File type is not allowed";
      fileInfo.statusCode = "0";
      
      return false;
    }

    return true;
  }

  createExtendedFileInfo(fileInfo: FileInfo): ExtendedFileInfo{
  
    let fileNameStartIndex: number = fileInfo.name.lastIndexOf("/") + 1;

    let relativeFilePath: string = fileInfo.name.substring(0, fileNameStartIndex);
    let fileName: string = fileInfo.name.substring(fileNameStartIndex);

    return {
      uploadId: crypto.randomUUID(),
      baseFilePath: this.currentFilePath,
      relativeFilePath: relativeFilePath,
      fileName: fileName,
      totalFileSize: fileInfo.size,
      documentRequestItemId: this.selectedDocumentRequestItemId 
    };
  }

  onChunkUploading(args: UploadingEventArgs): void {
    this.addFileUploadData(args);
  }

  onUploading(args: UploadingEventArgs): void {
    this.activeUploadCount += 1;
    
    if(this.windowTitlebarState != WindowTitlebarState.Uploading) {
      this.setWindowTitlebarState(WindowTitlebarState.Uploading);
    }

    this.addFileUploadData(args);
  }

  addFileUploadData(args: UploadingEventArgs){
    let extendedFileInfo = this.extendedFileInfo[args.fileData.id!];
    
    args.customFormData = [
      { "clientId": this.clientId },
      { "uploadId": extendedFileInfo.uploadId },
      { "documentRequestItemId": extendedFileInfo.documentRequestItemId },
      { "baseFilePath": extendedFileInfo.baseFilePath },
      { "relativeFilePath": extendedFileInfo.relativeFilePath },
      { "fileName": extendedFileInfo.fileName },
      { "totalFileSize": extendedFileInfo.totalFileSize },
      { "uploadedBy": this.user!.Email! }
    ];
  }

  onSuccess(args: Object): void {
    this.successfulUploadCount += 1;
  }

  onFailure(args: Object): void {
  }

  onRemoving(args: RemovingEventArgs): void {
  }

  onActionComplete(args: ActionCompleteEventArgs): void {
    this.documentUiService.triggerUploaderActionComplete(args);

    let totalFileCount: number = args.fileData.length;
    let successfulFileCount: number = args.fileData.filter(f => f.statusCode == "2").length;

    if(successfulFileCount == totalFileCount){
      this.setWindowTitlebarState(WindowTitlebarState.Success);
      this.notifyService.success("Upload completed");

      this.hideWindowTimeout = setTimeout(() => { 
        this.setUploadWindowVisible(false);
        this.clearUploaderList(); 
      }, 5000);
    }
    else {
      this.setWindowTitlebarState(WindowTitlebarState.Error);
      this.notifyService.error("Upload completed with error(s)");
    }
  }

  clearUploaderList(): void {
    this.uploader.clearAll();
    this.resetFileCounts();
    this.extendedFileInfo = {};
    this.setWindowTitlebarState(WindowTitlebarState.Default);
  }

  resetFileCounts(){
    this.activeUploadCount = 0;
    this.successfulUploadCount = 0;
  }
}
