import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss']
})
export class UserAvatarComponent implements OnChanges {

  @Input() fullName: string = "";

  initials: string = "";

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['fullName']) {
      this.initials = this.getInitials(this.fullName);
    }
  }

  getInitials(fullName: string): string {
    if(!fullName) { return ""; }

    let names: string[] = fullName.split(" ");

    if(names.length == 0){
      return "";
    }
    else if (names.length == 1) {
      return names[0].charAt(0);
    }
    else {
      return `${names[0].charAt(0)}${names[names.length-1].charAt(0)}`;
    }
  }
}
