import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'alStatusDisplayName'
})
export class ALStatusDisplayNamePipe implements PipeTransform{

  alStatusDisplayNames: { [key: string]: string } = {
    "SIGNED BY GE": "Signed By Building Owner",
    "SIGNED BY CLIENT": "Signed By Client"
  };

  defaultDisplayName: string = "Pending";

  transform(value: string | null): string {
    if(!value){
      return this.defaultDisplayName;
    }

    return this.alStatusDisplayNames[value.toUpperCase()] || this.defaultDisplayName;
  }
}