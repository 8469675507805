<div class="gray-box" [ngClass]="{'bg-dark': darkBackground}">
  <div class="key-contact-heading">{{title | uppercase}}</div>
  <div class="gray-box-border my-2"></div>
	<ng-container *ngIf="contacts.length > 0; else noContactsMessage" >
		<ng-container *ngFor="let contact of contacts; let last = last">
			<div class="key-contact-item">
				<span><img src="/assets/icons/user-tag{{darkBackground ? '-light' : ''}}.svg" alt="" /></span>
				<div>
					<div class="key-contact-name">{{contact.name}}</div>
					<div class="key-contact-role">{{contact.role}}</div>
				</div>
			</div>
			<div *ngIf="contact.email" class="key-contact-item">
					<span><img src="/assets/icons/email-icon{{darkBackground ? '-white' : ''}}.svg" alt="" /></span>
					<span class="key-contact-info"><a href="mailto:{{contact.email}}">{{contact.email}}</a></span>
			</div>
			<div *ngIf="contact.phoneNumber" class="key-contact-item">
					<span><img src="/assets/icons/phone-icon{{darkBackground ? '-white' : ''}}.svg" alt="" /></span>
					<span class="key-contact-info">{{contact.phoneNumber| phonenumber}}</span>
			</div>
			<div *ngIf="!last" class="gray-box-border my-2"></div>
		</ng-container>
	</ng-container>
	<ng-template #noContactsMessage>
		<div>No contacts available</div>
	</ng-template>
</div>
